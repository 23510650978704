import React from 'react'

import Layout from '../../components/Layout'
import Properties from '../../components/Properties'

export default class PropertyIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container mt-0"
          style={{
            backgroundImage: `url('/img/hero-units.jpg')`,
          }}
        >
          <div className="full-width-image-container-content">
            <h1
              className="full-width-image-container-heading has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-desktop"
              style={{
                color: 'white',
                padding: '1rem',
              }}
            >
              Property
            </h1>
          </div>
        </div>
        <section className="section">
          <div className="container pt-4 pb-5">
            <Properties />
          </div>
        </section>
      </Layout>
    )
  }
}
