import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropertyGrid from './PropertyGrid'

export default () => (
  <StaticQuery
    query={graphql`
      query PropertiesQuery {
        allMarkdownRemark(
          sort: {
            order: DESC,
            fields: [frontmatter___date]
          }
          filter: {
            frontmatter: {
              templateKey: { eq: "property" }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                location {
                  lat
                  lng
                  province
                }
                price {
                  rental
                  selling
                }
                freeTransfer
                size
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featured
                cardImage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PropertyGrid data={data} count={count} />}
  />
)
